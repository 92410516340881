import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: "Lenny Roland",
    location: "Montpellier, FR",
    tagline: "Développeur | Chef de projet",
    email: "contact@lennyroland.com",
    availability: "En recherche d'alternance",
    brand:
      "Toujours en recherche de nouvelles aventures. Je souhaite me spécialiser en gestion de projet, afin de faire de la gestion mon métier et du développement un loisir contant.",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
